import { Grid, Text } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

export const AuthPage = styled.section(
  ({ theme: { colors, spacing, radii, media } }) => css`
    background-color: ${colors.primary.main};
    background-image: url('/assets/auth-background.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem; // avoid row negative margin

    flex: 1 0 100%;
    box-sizing: border-box;

    ${Grid.Row} {
      min-width: 100%;
    }

    ${Grid.Col} {
      border-radius: ${radii.xs};
      padding: ${spacing.xl} ${spacing.lg};
      background-color: ${colors.neutral.lightest};
      color: ${colors.primary.main};
      text-align: center;
      > ${Text} {
        margin-bottom: ${spacing.lg};
      }

      > form {
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }

    ${media.lg} {
      padding: 0 0.75rem;
    }
  `,
)
