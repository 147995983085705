import { Dialog, Grid, Icon, Text } from '@lemonenergy/lemonpie'
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import { redirect, json } from '@remix-run/node'
import {
  useLoaderData,
  useNavigate,
  useOutlet,
  useRouteLoaderData,
  useSearchParams,
} from '@remix-run/react'

import Toast from '../../components/Toast/Toast'
import { getAuthorizedUserFromRequest } from '../../controllers.server/auth'
import { createCognitoSSOFormMockedHandler } from '../../utils/auth.util/auth.mock'
import { makeSSOAuthenticationForm } from '../../utils/auth.util/auth.service.server'

import { AuthPage } from './components/AuthPage'
import { GoogleButton } from './components/GoogleButton'

export { default as ErrorBoundary } from '../../components/ErrorBoundary'

export const links: LinksFunction = () => {
  return [
    {
      href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap',
      rel: 'stylesheet',
    },
  ]
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  if (!process.env.COGNITO_BASE_URL) {
    throw new Error('COGNITO_BASE_URL is not defined')
  }
  if (!process.env.COGNITO_CLIENT_ID) {
    throw new Error('COGNITO_CLIENT_ID is not defined')
  }
  if (!process.env.COGNITO_CLIENT_SCOPE) {
    throw new Error('COGNITO_CLIENT_SCOPE is not defined')
  }

  const url = new URL(request.url)
  const redirectTo = url.searchParams.get('redirectTo') || '/'

  const ssoCallbackUrl = new URL(process.env.BASE_URL || '')
  ssoCallbackUrl.pathname = '/login/callback'

  const user = await getAuthorizedUserFromRequest(request)

  if (user) {
    return redirect(redirectTo)
  }

  const cognitoSSOForm = makeSSOAuthenticationForm({
    provider: 'Google',
    cognitoBaseUrl: process.env.COGNITO_BASE_URL,
    cognitoClientId: process.env.COGNITO_CLIENT_ID,
    ssoClientScope: process.env.COGNITO_CLIENT_SCOPE,
    ssoCallbackUrl: ssoCallbackUrl.toString(),
    successRedirectPath: redirectTo,
  })

  return json({ cognitoSSOForm })
}

const Login = () => {
  const rootData = useRouteLoaderData('root') as {
    ENABLE_MOCKED_SERVICE_WORKER: boolean
  }
  const loaderData = useLoaderData<typeof loader>()

  const outlet = useOutlet()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const handleSubmit = rootData.ENABLE_MOCKED_SERVICE_WORKER
    ? createCognitoSSOFormMockedHandler(loaderData.cognitoSSOForm, navigate)
    : undefined

  const error = searchParams.get('error')

  const redirectTo = encodeURIComponent(searchParams.get('redirectTo') || '')
  const loginPath = '/login' + (redirectTo ? `?redirectTo=${redirectTo}` : '')

  const navigateToLogin = () => navigate(loginPath, { replace: true })

  return (
    <>
      <AuthPage>
        <Grid.Row align="center" justify="center">
          <Grid.Col xs={8} md={6} lg={4}>
            <Text variant="subtitle100" bold>
              Login
            </Text>
            <form
              action={loaderData.cognitoSSOForm.action}
              onSubmit={handleSubmit}
            >
              {Object.entries(loaderData.cognitoSSOForm.fields).map(
                ([key, value]) => {
                  return (
                    <input
                      key={key}
                      type="hidden"
                      name={key}
                      value={value || ''}
                      hidden
                    />
                  )
                },
              )}
              <GoogleButton data-testid="auth-button" type="submit">
                Entrar com Google
              </GoogleButton>
            </form>
          </Grid.Col>
        </Grid.Row>
      </AuthPage>
      <Dialog
        onClose={navigateToLogin}
        isOpen={Boolean(outlet)}
        data-testid="authentication-error-dialog"
      >
        {outlet}
      </Dialog>
      <Toast
        onClose={navigateToLogin}
        isOpen={error === 'authorization_required'}
        data-testid="authorization-error-toast"
      >
        <Text variant="body200" bold>
          Não autorizado! Refaça o login.
        </Text>
        <Icon size="xs" name="cross" onClick={navigateToLogin} />
      </Toast>
    </>
  )
}

export default Login
