import type { NavigateFunction } from '@remix-run/react'
import type { FormEventHandler } from 'react'

import type { CognitoSSOForm } from './types'

export const createCognitoSSOFormMockedHandler =
  (
    cognitoSSOForm: CognitoSSOForm,
    navigate: NavigateFunction,
  ): FormEventHandler<HTMLFormElement> =>
  async (ev) => {
    ev.preventDefault()

    const requestUrl = new URL(cognitoSSOForm.action)

    Object.entries(cognitoSSOForm.fields).forEach(([key, value]) => {
      requestUrl.searchParams.set(key, value || '')
    })

    const response = await fetch(requestUrl)

    window.location.href = response.url
  }
